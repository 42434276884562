import React from 'react';

export default function BotLogo({ width , height}) {
	return (<svg width={width} height={height} viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43.891 22.4761C43.891 34.5574 34.1141 44.3512 22.0538 44.3512C9.9935 44.3512 0.216675 34.5574 0.216675 22.4761C0.216675 10.3949 9.9935 0.601074 22.0538 0.601074C34.1141 0.601074 43.891 10.3949 43.891 22.4761Z" fill="white"/>
    <path d="M21.3897 16.4448C21.3405 15.7428 21.1581 15.0573 20.8529 14.4267C20.5427 13.7887 20.1177 13.2165 19.6001 12.7401C18.539 11.7625 17.1614 11.2228 15.734 11.2256C14.7392 11.2277 13.7555 11.4391 12.8438 11.8466C11.9321 12.2541 11.1119 12.8491 10.4341 13.5946C9.83119 14.2384 9.36584 15.0038 9.06775 15.8419C8.76966 16.68 8.64536 17.5725 8.70279 18.4626C8.90756 21.388 11.3828 23.6819 14.3347 23.6819C15.3334 23.6781 16.3207 23.4643 17.2354 23.0538C18.1501 22.6433 18.9727 22.0448 19.6522 21.2954C20.2548 20.6546 20.7205 19.8925 21.0196 19.0573C21.3188 18.2222 21.4448 17.3325 21.3897 16.4448ZM17.4456 19.7609C17.0575 20.2 16.5855 20.553 16.0589 20.798C15.5323 21.0429 14.9622 21.1748 14.3839 21.1852C13.6447 21.1816 12.934 20.8928 12.3937 20.3763C11.8533 19.8599 11.523 19.1538 11.4686 18.3989C11.4353 17.8012 11.522 17.2029 11.7234 16.6409C11.9248 16.0789 12.2365 15.5651 12.6395 15.1313C13.0253 14.6956 13.4944 14.3457 14.0178 14.1034C14.5411 13.8611 15.1076 13.7316 15.6819 13.7228C16.4245 13.7232 17.1397 14.0103 17.6847 14.5267C18.2298 15.0432 18.5646 15.7512 18.6223 16.5093C18.6533 17.1048 18.5649 17.7006 18.3626 18.2598C18.1603 18.8191 17.848 19.3297 17.4456 19.7609Z" fill="#484848"/>
    <path d="M34.8507 16.4448C34.8014 15.7428 34.6191 15.0573 34.3139 14.4267C34.0045 13.7883 33.5793 13.2159 33.0611 12.7401C32.001 11.7622 30.6239 11.2225 29.1971 11.2256C28.202 11.2279 27.218 11.4393 26.306 11.8468C25.394 12.2543 24.5733 12.8492 23.895 13.5946C23.2927 14.2388 22.828 15.0043 22.5303 15.8424C22.2326 16.6804 22.1084 17.5727 22.1657 18.4626C22.3705 21.388 24.8438 23.6819 27.7956 23.6819C28.7945 23.6783 29.7821 23.4646 30.6971 23.0541C31.6121 22.6436 32.435 22.0449 33.1148 21.2954C33.7176 20.6549 34.1833 19.8927 34.4822 19.0575C34.7811 18.2223 34.9066 17.3324 34.8507 16.4448ZM30.9066 19.7609C30.519 20.2001 30.0475 20.5532 29.5212 20.7982C28.9949 21.0432 28.425 21.175 27.847 21.1852C27.1076 21.1824 26.3966 20.8938 25.8561 20.3772C25.3156 19.8606 24.9855 19.154 24.9319 18.3989C24.8981 17.8014 24.9844 17.2032 25.1854 16.6411C25.3864 16.0791 25.6978 15.5652 26.1005 15.1313C26.4869 14.6957 26.9565 14.346 27.4802 14.1037C28.0039 13.8615 28.5706 13.7318 29.1452 13.7228C29.8878 13.7227 30.603 14.0097 31.1479 14.5263C31.6928 15.0429 32.0271 15.7511 32.084 16.5093C32.1159 17.105 32.0279 17.701 31.8255 18.2604C31.623 18.8198 31.3099 19.3303 30.9066 19.7609Z" fill="#484848"/>
    <path d="M29.8641 25.4886C28.7919 27.153 27.3315 28.5175 25.6149 29.4587C23.8983 30.4 21.9796 30.8882 20.0324 30.8794C19.2921 30.8796 18.5534 30.8097 17.8257 30.6704L17.7452 30.6549L17.2332 33.4533L17.3135 33.4686C18.2101 33.6401 19.1203 33.7263 20.0324 33.7262C22.4404 33.7359 24.8128 33.1308 26.935 31.9656C29.0573 30.8005 30.8624 29.112 32.1872 27.053L32.2321 26.983L29.9092 25.4185L29.8641 25.4886Z" fill="#3776F4"/>
    </svg>);    
}
